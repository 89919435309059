h3 {
  margin-bottom: 22px;
  line-height: 30px;
  color: #333;
  font-weight: 500;
  font-size: 22px;
}
h5 {
  margin-bottom: 16px;
  color: #333;
  font-size: 18px;
  line-height: 25px;
}
ul > li {
  margin-bottom: 24px;
  line-height: 28px;
  color: #333;
  font-size: 16px;
}
p {
  line-height: 1.5;
}
