


















































































































.content {
  padding-bottom: 189px;
}
.banner {
  width: 100%;
  height: 220px;
  margin-top: 4px;
  background-image: url(https://mktv-in-cdn.mockuai.com/16167531645959022.png);
  background-size: cover;
}
.intro {
  box-sizing: border-box;
  margin-top: 60px;
  padding: 24px 20px;
  background-color: #f2f7ff;
  color: #333;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 16px;
  line-height: 30px;
}
.complain {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 180px;
  margin-top: 32px;
  padding-top: 39px;
  background-color: #f7f8fb;
  cursor: pointer;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    .icon {
      width: 26px;
      height: 23px;
      margin-right: 16px;
    }
    .text {
      font-size: 24px;
      font-weight: 500;
      color: #333;
    }
  }
  .desc {
    line-height: 26px;
    font-size: 16px;
    color: #535457;
  }
}
.record {
  padding: 30px 0;
  color: #333;
  font-size: 16px;
  line-height: 22px;
  .link {
    cursor: pointer;
    color: #007cff;
  }
}
.material {
  display: flex;
  .menus {
    margin-right: 154px;
    .menu {
      position: relative;
      width: 240px;
      padding: 20px 0;
      font-size: 18px;
      color: #666;
      cursor: pointer;
      &.active {
        color: #ff6000;
        font-size: 20px;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-radius: 1px;
        background: #ededed;
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        &::after {
          content: '';
          width: 0;
          height: 0;
        }
      }
    }
  }
  .panel {
    flex: 1;
    .img {
      width: 807px;
      height: 120px;
      margin-bottom: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
